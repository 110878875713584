import React, { useState } from "react"
import { GiHamburgerMenu } from "react-icons/gi"

import styled from "styled-components"

const Container = styled.div`
ul {
    display: flex;
    align-items: center;
    li {
        font-size: 1rem; 
        padding: 0px 10px;
        a {
            border-bottom: 1px dashed; 
        }
        @media (max-width: 350px) {
            font-size: 0.8rem;
          }
    }
}
`

export default () => {

    const [open, setOpen] = useState(false)

    return (
        <Container className="d-block d-md-none">
            <ul className={`${open?'open':'close'}`}>
                <li id="menu-item-1870" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1870"><a href="#advantages">Преимущества</a></li>
                <li id="menu-item-1871" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1871"><a href="#rules">Как мыть</a></li>
                <li id="menu-item-1873" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1873"><a href="#map_wrapper">Адрес</a></li>
            </ul>
        </Container>
    )
}
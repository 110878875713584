import React from 'react';

class YouTubeVideo extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = {bufferOpacity: 1}
        this.onYoutubePlayerReady = this.onYoutubePlayerReady.bind(this)
        this.onYoutubePlayerStateChange = this.onYoutubePlayerStateChange.bind(this)
        this.loadVideo = this.loadVideo.bind(this)
    }

    componentDidMount = () => {
        // On mount, check to see if the API script is already loaded

        if (!window.YT) { // If not, load the script asynchronously

            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';

            // onYouTubeIframeAPIReady will load the video after the script is loaded
            window.onYouTubeIframeAPIReady = this.loadVideo;

            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        } else { // If script is already there, load the video directly
            this.loadVideo();
        }
    }

    onYoutubePlayerReady(event) {
        event.target.mute();
        event.target.playVideo();
    }

    onYoutubePlayerStateChange(event) {
        if (event.data === window.YT.PlayerState.PLAYING) { // fade out #_buffering-background
            this.setState({bufferOpacity: 0})
        }

        if (event.data == window.YT.PlayerState.ENDED) { // loop video
            event.target.playVideo();
        }
    }

    loadVideo = () => {
        const { videoId } = this.props;

        this.player = new window.YT.Player("_youtube-iframe", {
            videoId,
            playerVars: { // https://developers.google.com/youtube/player_parameters?playerVersion=HTML5
                cc_load_policy: 0, // closed caption
                controls: 0,
                disablekb: 0, //disable keyboard
                iv_load_policy: 3, // annotations
                playsinline: 1, // play inline on iOS
                rel: 0, // related videos
                showinfo: 0, // title
                modestbranding: 3 // youtube logo
            },
            events: {
                'onReady': this.onYoutubePlayerReady,
                'onStateChange': this.onYoutubePlayerStateChange
            }
        });
    };


    render = () => {
        return (
            <div>
                <div className="fullscreen-video-background">
                    <div className="_pattern-overlay"></div>
                    <div id="_buffering-background" style={{opacity: this.state.bufferOpacity}}></div>
                    <div id="_youtube-iframe-wrapper">
                        <div id="_youtube-iframe"></div>
                    </div>
                    {this.props.children}
                </div>
            </div>
        );
    };
}

export default YouTubeVideo;
import React from "react"

const component = ({}) => (
  <section id="top_line">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 col-md-8 col-sm-6 col-5">
          <ul className="menu">
            <li>
              <a href="#map_wrapper" className="basic_city">
                НОВОАЛТАЙСК
              </a>
            </li>
          </ul>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-7">
          <a
            href="https://rtsp.me/embed/BtRrEyTa/"
            className="callback basic pulse"
            target="_blank"
          >
            <i className="fa fa-play-circle-o" aria-hidden="true"></i>
            &nbsp;&nbsp;ОНЛАЙН-ТРАНСЛЯЦИЯ
          </a>
        </div>
      </div>
    </div>
  </section>
)

export default component

import React, { useState, useEffect } from "react"
import TopLine from "./TopLine"
import styled from "styled-components"
import MobileMenu from "./MobileMenu"
import YouTubeVideo from "./YoutubeVideo"

const Slide = styled.li`
  height: 700px;
  position: relative;

  @media (max-width: 400px) {
    height: 80vh;
  }

  .popup-label {
    width: 0px;
    height: 0px;
    border-radius: 50%;
    background-color: yellow;
    color: #000;
    z-index: 5;
    position: absolute;
    bottom: -130px;
    right: -100px;
    padding: 150px;
    -webkit-transition: width 0.5s, height 0.5s;
    transition: width 0.5s, height 0.5s;
    .title {
      line-height: 70px;
      font-size: 62px;
      font-weight: bolder;
      margin-bottom: 30px;
      @media (max-width: 400px) {
        font-size: 16px;
        line-height: 20px;
      }
    }

    @media (max-width: 400px) {
      bottom: -30px;
      right: -10px;
    }

    a {
      padding-left: 10px;
    }
  }

  .popup-label.open {
    width: 700px;
    height: 700px;
    @media (max-width: 400px) {
      width: 200px;
      height: 200px;
      bottom: -30px;
      right: -10px;
      padding: 40px;
    }
  }
`
export default () => {
  const [open, setOpen] = useState(false)

  setInterval(() => setOpen(true), 3000)

  return (
    <header>
      <TopLine />
      <section id="logo_menu">
        <div className="container">
          <div className="row">
            <div className="col-3 col-sm-3 col-md-2 col-lg-5 d-flex">
              <div className="logo wow fadeIn"></div>
              <div className="d-flex align-items-center">
                <div className="logo-slogan">
                  Автомойка самообслуживания. <br />
                  БЫСТРО И ЧИСТО. <i>Делаем роскошь дёшево</i>{" "}
                </div>
              </div>
            </div>
            <div
              className="col-9 col-sm-9 col-md-10 col-lg-7"
              style={{ padding: 12 }}
            >
              <div className="menu-main_menu-container">
                <ul id="menu-main_menu" className="menu">
                  <li
                    id="menu-item-1870"
                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1870"
                  >
                    <a href="#advantages">Преимущества</a>
                  </li>
                  <li
                    id="menu-item-1871"
                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1871"
                  >
                    <a href="#rules">Правила пользования</a>
                  </li>
                  <li
                    id="menu-item-1873"
                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1873"
                  >
                    <a href="#map_wrapper">Адрес</a>
                  </li>
                </ul>
              </div>
              <MobileMenu />
            </div>
          </div>
        </div>
      </section>

      <section>
        <YouTubeVideo videoId="AvjUA3pdst8" style={{ height: 700 }}>
          <div id="outerslider" style={{ zIndex: 2, position: "relative" }}>
            <div id="slidercontainer">
              <section id="slider">
                <div id="slideritems" className="flexslider preloader">
                  <ul className="slides">
                    <Slide className="slide">
                      <div className="container d-flex align-items-center">
                        <div className="banner-title">
                          <h1>
                            Теплая, круглосуточная автомойка самообслуживания в
                            Новоалтайске.
                          </h1>
                        </div>
                      </div>
                      <div
                        className={`popup-label${
                          open ? " open" : ""
                        } basic pulse`}
                      >
                        <div className="title">Открыто 24/7</div>{" "}
                        <a href="#development">Узнать подробнее</a>
                      </div>
                    </Slide>
                  </ul>
                </div>
              </section>
            </div>
          </div>
        </YouTubeVideo>
      </section>
    </header>
  )
}
